#SearchModal .modal-body {
    height: 400px;
    overflow-y: auto;
}

.search-filter-list {
    margin-top: 10px;
    .label {
        font-size: 100%;
    }
}
