// BUTTONS
// -------
.btn {
  font-weight: normal;
}

.btn-group > .btn,
.btn-group > .dropdown-menu,
.btn {
  font-size: 12px;
  font-family: inherit;
}

.btn-primary,
.btn-danger {
  font-weight: bold;
}

td .btn-toolbar {
  margin: 0;
}


.control-group.pull-left.no-float {
  > label {
    width: 140px;
  }
  .controls {
    margin-left: 160px;
  }
}

.pull-left.btn {
  margin-left: 20px;
}

.pull-left.no-float.btn {
  margin-left: 160px;
}

.controls {
  ul {
    margin-left:0px;
    list-style:none;
  }
}

.fixed-actions {
  margin-bottom: 180px;
  .fixed-actions-group {
    margin: 0;
    z-index: 1010;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    background: #2a2a2a;
  }
  .form-group {
    margin: 0;
  }
}

