// FORM CHANGES
// -----------
form.flat {
  margin: 0;
}

.form-horizontal,
.form-stacked {
  .control-label {
    text-align: left;
  }
  .control-group {
    margin-bottom: @line-height-base / 2;
  }
  input[type=text],
  input[type=number],
  input[type=email],
  input[type=url],
  textarea,
  select {
    font-size: 12px;
  }
  textarea {
    max-height: 200px;
  }
}

.form-inline {
  select {
    min-width: 200px;
  }
  .select2-container.form-control {
      display: inline-block;
  }
  .form-group .form-inline {
      display: inline-block;
  }
}

.form-inline {
  margin-bottom: 0;
  .help-block:first-child, .error-block:first-child {
    display: none;
  }
  label {
    margin-right: 5px;
  }
  .control-group {
    margin-right: 10px;
  }
  .product-select{
    width: 270px;
  }
}

.create-page {
  .form-horizontal,
  .form-stacked {
    input[type=text],
    input[type=number],
    input[type=email],
    input[type=url],
    textarea {
      width:95%;
    }
  }
  .form-stacked {
    .form-inline {
      input[type=text],
      input[type=number],
      input[type=email],
      input[type=url],
      textarea {
        width:auto;
      }
    }
  }
  label {
    font-weight:bold;
  }
}

.form-stacked {
  .error-block {
    display: block;
    color: #b94a48;
  }
}

form {
  .form-group .form-inline {
    .input-group.date input {
      width: 100%;
    }

    @media (min-width: @screen-sm-min) {
      .input-group.date input {
        width: 145px;
      }
    }
  }
}

.fields-full {
  input[type=text],
  textarea {
    width: 100%;
  }
}

textarea.plain {
	font-family: monospace;
}

.well,
.tabbable.dashboard .tab-content {
  .form-actions {
    margin-bottom: 0;
    position: relative;
    bottom: -19px;
    left: -19px;
    width: 100%;
    padding: 19px;
    border-top-color: #ccc;
  }
}

.tabbable.dashboard .tab-content {
  .form-actions {
    bottom: -50px;
    left: -29px;
    padding-left: 29px;
    padding-right: 29px;
  }
}

.wysihtml5-sandbox {
    border: 1px solid #ccc!important;
}

.wysihtml5-toolbar {
  .clearfix();
}

textarea {
	width: 500px;
	height: 400px;
}

/* ADDITIONAL ERRORS */
.errorlist {
  margin: 0 0 0 20px;
  padding: 0 0 10px 0;
  color: #B94A48;
  li {
    padding: 0 0 5px 0;
    list-style: none;
  }
}

.form-inline {
  .help-block, .error-block {
    display: inline-block;
    margin: 0 20px 0 10px;
    li {
      list-style: none;
      color: #B94A48;
    }
  }
  input[type=number],
  input[type=email],
  input[type=url],
  input[type=text] {
    width: 140px;
  }
}
