// TABLE CHANGES
// -----------
.table-bordered,
.table-bordered th,
.table-bordered td  {
  border-color:#bbb;
}

.table {
  background-color: @white;
}

.table thead tr th.asc i:before {
  content: "\f077";
}

.table thead tr th.desc i:before {
  content: "\f078";
}

.table-striped tbody tr:nth-child(odd) td,
.table-striped tbody tr:nth-child(odd) th {
  background-color: #efefef;
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
  background-color: #FFF49F;
}

caption,
.table-header {
  .clearfix();
  .icon-large {
    &:before {
      font-size: 18px;
    }
  }
  h2,h3 {
    font-weight: bold;
    font-size: 14px;
    line-height: normal;
    margin:0;
    > i {
      padding-right: 10px;
    }
  }
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 0;
  border: 1px solid #ccc;
  border-bottom: none;
  #gradient > .vertical(#F8F8F8, #e5e5e5);
  color: #242424;
  padding: 6px 10px;
  .border-radius(3px 3px 0 0);
  text-align: left;
  // line-height: 28px;
  .btn-toolbar {
    margin-top:0;
    margin-bottom:0;
  }
  > i {
    padding-right: 10px;
  }
}


