.datetimepicker {
  .glyphicon {
    .icon-FontAwesome();

    &.glyphicon-arrow-left:before { &:extend(.icon-arrow-left:before); }
    &.glyphicon-arrow-right:before { &:extend(.icon-arrow-right:before); }
  }
}

.form-inline .form-group .form-inline {
    display: inline-block;
}
