// Styles for jquery-sortable

body.dragging, body.dragging * {
  cursor: move !important;
}

.draggable {
  background-color: #FFF49F;
}

.dragged {
  position: absolute;
  opacity: 0.5;
  z-index: 2000;
}